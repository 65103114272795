<script lang="ts" setup>
const props = withDefaults(defineProps<{
  title?: string
  hiddenTitle?: boolean
  type?: 'search' | 'back'
}>(), {
  title: '',
  hiddenTitle: false,
  type: 'back',
})
const { isDesktop } = useDevice()
</script>

<template>
  <layout-nav v-if="!hiddenTitle && !isDesktop" :title="props.title" :type="props.type" />
  <slot />
</template>
